import AuthServices from './auth';
import Http from './http';
import MenuServices from './menu';
import ProductServices from './product';

const http = new Http();

export default {
  authServices: new AuthServices({ http }),
  productServices: new ProductServices({ http }),
  menuServices: new MenuServices()
};
