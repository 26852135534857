export const SERIES = {
  BALANCE: {
    key: 'balance',
    text: 'Balance',
    title: 'Balance Series Comparison Table',
    description: 'Find the best Balance Router for you',
  },
  MAX: {
    key: 'max',
    text: 'MAX',
    title: 'MAX and B One Series Comparison Table',
    description: 'Find the best MAX and B One Series Router for you',
  },
};

export const DEFAULT_PRODUCTS = {
  balance: {
    product1: 'BPL-021',
    product2: 'BPL-021X-LTE-US-T-PRM',
    product3: 'BPL-031-LTE-US-T',
  },
  max: {
    product1: 'MAX-BR1-MINI-LTEA-W-T-PRM',
    product2: 'MAX-BR1-PRO-5GH-T-PRM',
    product3: 'MAX-HD4-MBX-LTEA-R-T',
  },
};

export const HIDDEN = [];

export const MAIN_WEBSITE_URL = 'https://www.peplink.com';
export const HEADER_LOGO_URL = '//www.peplink.com/wp-content/uploads/2020/04/peplink_logo.svg';
export const HEADER_LOGO_SRCSET = [
  '(min-width:0px) and (max-width:544px)',
  '(min-width:544px) and (max-width:768px)',
  '(min-width:768px) and (max-width:992px)',
  '(min-width:992px) and (max-width:1200px)',
  '(min-width:1200px)',
];

export const AP_COMPARISON_PAGE = 'ap-comparison-page';
export const AP_COMPARISON_PAGE_URL = '//www.peplink.com/products/ap-one-series-comparison-table';

export const AP_SD_SWITCH_PAGE = 'sd-switch-page';
export const AP_SD_SWITCH_PAGE_URL = 'https://www.peplink.com/products/sd-switch-series-comparison-table';
