import Collapse from './Collapse';
import Data from './Data';
import React from 'react';

export default ({ product1 = {}, product2 = {}, product3 = {} }) => {
  return (
    <>
      <Collapse header="Quick Look" defaultOpen={true}>
        <Data
          quickLook
          data={{
            col1: product1['Quick Look'],
            col2: product2['Quick Look'],
            col3: product3['Quick Look'],
          }}
        />
      </Collapse>
      <Collapse header="Interfaces" defaultOpen={true}>
        <Data
          data={{
            col1: product1['Interfaces'],
            col2: product2['Interfaces'],
            col3: product3['Interfaces'],
          }}
        />
      </Collapse>
      <Collapse header="Performance" defaultOpen={true}>
        <Data
          data={{
            col1: product1['Performance'],
            col2: product2['Performance'],
            col3: product3['Performance'],
          }}
        />
      </Collapse>
      <Collapse header="Wireless details" defaultOpen={true}>
        <Data
          data={{
            col1: product1['Wireless details'],
            col2: product2['Wireless details'],
            col3: product3['Wireless details'],
          }}
        />
      </Collapse>
      <Collapse header="Features" defaultOpen={true}>
        <Data
          data={{
            col1: product1['Features'],
            col2: product2['Features'],
            col3: product3['Features'],
          }}
        />
      </Collapse>
      <Collapse header="VPN Functionality">
        <Data
          data={{
            col1: product1['VPN Functionality'],
            col2: product2['VPN Functionality'],
            col3: product3['VPN Functionality'],
          }}
        />
      </Collapse>
      <Collapse header="Hardware">
        <Data
          data={{
            col1: product1['Hardware'],
            col2: product2['Hardware'],
            col3: product3['Hardware'],
          }}
        />
      </Collapse>
      <Collapse header="Certifications">
        <Data
          data={{
            col1: product1['Certifications'],
            col2: product2['Certifications'],
            col3: product3['Certifications'],
          }}
        />
      </Collapse>
      <Collapse header="Remarks" className="leading-snug whitespace-pre-wrap">
        <div className="mb-8 grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-16">
          <div>
            {product1['Remarks'] &&
              product1['Remarks'].map((item) => {
                return item.value;
              })}
          </div>
          <div>
            {product2['Remarks'] &&
              product2['Remarks'].map((item) => {
                return item.value;
              })}
          </div>
          <div className="hidden lg:block xl:block">
            {product3['Remarks'] &&
              product3['Remarks'].map((item) => {
                return item.value;
              })}
          </div>
        </div>
      </Collapse>
      <p className=" my-20 footnote">
        [1] The frequency range for 2.4GHz and 5GHz are 2412−2472 MHz and 5180−5825 MHz respectively.
        <br />
        [2] Recommendated user: Recommendation for sizing purposes only. No software restrictions applied. <br />
        [3] Router Throughput and PepVPN/SpeedFusion Throughput is based on Ethernet frame sizes of 1280 or 1518 bytes.
        Actual performance can vary across different networking environments. Figures apply to the latest hardware
        revision. The throughput result is tested with DHCP/Static IP. <br />
        [4] Transmit power can be configured on the Web Admin Interface. <br />
        [5] Cache Throughput refers to the speed of routing content from disk drive to LAN. <br />
        [6] All models that are compatible with Firmware 8.5.0 or above can support IP Passthrough. <br />
      </p>
    </>
  );
};
