import cx from 'classnames';
import React from 'react';

export default ({ header, children, defaultOpen = false, className }) => {
  const [isOpen, setIsOpen] = React.useState(defaultOpen);

  return (
    <div className={cx('flex flex-col justify-between bg-white rounded', className)}>
      <div className="group-attribute-title" onClick={() => setIsOpen(!isOpen)}>
        <h3 className="mb-2 font-bold text-primary">{header}</h3>
        {isOpen ? (
          <span className="material-icons md-expand-down">expand_more</span>
        ) : (
          <span className="material-icons md-expand-right">chevron_right</span>
        )}
      </div>
      <hr className="mb-8" />
      {isOpen && children}
    </div>
  );
};
