import { HIDDEN } from 'definitions';
import Attribute from 'components/common/Attribute';
import React from 'react';

export default ({ data = {}, quickLook }) => {
  const [count, setCount] = React.useState(0);

  const { col1 = [], col2 = [], col3 = [] } = data;
  React.useEffect(() => {
    if (col1.length > count) {
      setCount(col1.length);
    }
    if (col2.length > count) {
      setCount(col1.length);
    }
    if (col3.length > count) {
      setCount(col1.length);
    }
  }, [col1.length, col2.length, col3.length, count]);

  const items = React.useMemo(() => {
    const i = [];
    for (let ctr = 0; ctr <= count; ctr++) {
      if (!HIDDEN.find((i) => i === col1[ctr]?.label)) {
        i.push([col1[ctr], col2[ctr], col3[ctr]]);
      }
    }
    return i;
  }, [col1, col2, col3, count]);

  return (
    <div id={quickLook && 'quick-look'}>
      {items.map((item, key) => {
        return (
          <div
            key={key}
            className="mb-8 grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-16"
          >
            <Attribute quickLook={quickLook} label={item[0]?.label} value={item[0]?.value} />
            <Attribute quickLook={quickLook} label={item[1]?.label} value={item[1]?.value} />
            <Attribute
              className="hidden lg:block xl:block"
              quickLook={quickLook}
              label={item[2]?.label}
              value={item[2]?.value}
            />
          </div>
        );
      })}
    </div>
  );
};
