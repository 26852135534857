import React from 'react';

export default ({ data }) => {
  return data[0].contents.map((item, index) => (
    <div key={index} className="menu-section">
      <p>{item.link ? <a href={item.link}>{item.title}</a> : <span>{item.title}</span>}</p>
      <div className="menu-item double-item">
        {item.type === 'subitem' ? (
          <>
            <div class="subitem  ">
              {item.items.map((element) => (
                <>
                  <div class="sub-title">{element.item}</div>
                  <div class="sub">
                    {element.subitem.map((el, index) => (
                      <>
                        <div key={index} class="sub-title-item">
                          <a className={index === 0 ? 'first-link' : ''} href={el.link}>
                            {el.text}
                          </a>
                        </div>
                        {index !== element.subitem.length - 1 && <>|</>}
                      </>
                    ))}
                  </div>
                </>
              ))}
            </div>
            <div class="subitem remaining-items "></div>
          </>
        ) : (
          <>
            <div class="single-item ">
              {item.items.map((ele) => (
                <a key={ele.text} href={ele.link}>
                  {ele.text}
                </a>
              ))}
            </div>
            <div class="single-item remaining-items "></div>
          </>
        )}
      </div>
    </div>
  ));
};
