import { BrowserRouter as Router } from 'react-router-dom';
import Footer from 'components/Footer';
import Header from 'components/Header';
import Main from 'components/Main';
import React from 'react';
import useMarkerIo from 'hooks/useMarkerIo';

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    window.location.hostname === '[::1]' ||
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

function App() {
  useMarkerIo({ enabled: !isLocalhost });
  return (
    <Router>
      <Header />
      <Main />
      <Footer />
    </Router>
  );
}

export default App;
