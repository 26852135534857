import axios from 'axios';

export default class MenuServices {
  constructor() {
    this.url = 'menus';
  }

  getMenus = () => {
    return axios.get(`${process.env.REACT_APP_WORDPRESS_MENU_API}${this.url}`);
  };

  getMenuItems = (id) => {
    return axios.get(`${process.env.REACT_APP_WORDPRESS_MENU_API}${this.url}/${id}`);
  }
}
