import { isEmpty } from 'lodash';
import cx from 'classnames';
import React from 'react';

export default ({ isLoading, options, name, onChange, data = {} }) => {
  const handleChange = (e) => {
    onChange(e.target.value, name);
  };

  return (
    <div>
      {data.resourcePath && (
        <img alt={data.productCode} src={`${process.env.REACT_APP_IMAGE_SERVER}/${data.resourcePath}`} />
      )}
      <h2 className={cx('pdf-show', { hidden: !isLoading })}>{data.name}</h2>
      <div className={cx('flex justify-center w-full mb-6', { hidden: isEmpty(data?.productCode) })}>
        <a
          href={`${process.env.REACT_APP_PDF_DOWNLOAD_URL}${data.productCode}.pdf?t=${+new Date()}`}
          download
          className="pdf-download-button"
        >
          <span>Tech Specs PDF</span>
          <img src="/assets/download-arrow.svg" alt={`Dowload ${data.productCode}.pdf`} />
        </a>
      </div>

      <select
        className={cx('select pdf-hide', { hidden: isLoading })}
        key={data.productCode}
        value={data.productCode}
        onChange={handleChange}
      >
        <option value="">Select...</option>
        {options.map(({ value, label }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </select>
    </div>
  );
};
