import React from 'react';

export default ({ enabled }) => {
  React.useEffect(() => {
    const script = document.createElement('script');
    if (enabled) {
      window.markerConfig = {
        destination: process.env.REACT_APP_MARKER_IO,
      };

      script.src = `//edge.marker.io/latest/shim.js`;
      script.async = true;
      document.body.appendChild(script);
    }
    return () => {
      if (enabled) {
        document.body.removeChild(script);
      }
    };
  }, [enabled]);
};
