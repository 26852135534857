import cx from 'classnames';
import HTMLReactParser from 'html-react-parser';
import React from 'react';

const SUPER = /([*#!@＠^^^~+~^&＆%$]{1,2})$/gm;

export default ({ label, value = '', quickLook, className }) => {
  const valueStr = React.useMemo(() => {
    let val =
      value.toString().toLowerCase() === 'true'
        ? 'Yes'
        : value.toString().toLowerCase() === 'false'
        ? 'No'
        : value.toString();

    val = val.replaceAll(SUPER, `<sup>$1</sup>`);

    return HTMLReactParser(val);
  }, [value]);

  return (
    <div className={cx(className, { 'justify-center': quickLook })}>
      <div className={cx('flex flex-col attribute-title', { 'text-center': quickLook })}>
        {label}
        <strong className={cx('mt-2 whitespace-pre-wrap attribute-value')}>{valueStr}</strong>
      </div>
    </div>
  );
};
