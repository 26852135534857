import React from 'react';

export default ({ data }) => {
  const firstColData = data[0].contents[0].items.slice(0, 4);
  const restData = data[0].contents[0].items.slice(4);

  return data[0].contents.map((item, index) =>
    item.type === 'subitem' ? (
      <div key={index} className="menu-section">
        <p>{item.title}</p>
        <div className="menu-item double-item">
          <div className="subitem">
            {firstColData.map((element) => (
              <>
                <a key={element.item} href={element.link}>
                  <div class="sub-title">{element.item}</div>
                </a>
                <div class="sub">
                  {element.subitem.map((el, index) => (
                    <div key={index} class="sub-title-item">
                      <a className={index === 0 ? 'first-link' : ''} href={el.link}>
                        {el.text}
                      </a>
                      {index !== element.subitem.length - 1 && <>|</>}
                    </div>
                  ))}
                </div>
              </>
            ))}
          </div>
          <div class="subitem remaining-items ">
            {restData.map((element) => (
              <>
                <a href={element.link}>
                  <div class="sub-title">{element.item}</div>
                </a>
                <div class="sub"></div>
              </>
            ))}
          </div>
        </div>

        {item.singleItem.length > 0 && (
          <div className="menu-item">
            {item.singleItem.map((element) => (
              <div className="single-item" key={element.text}>
                <a href={element.link}>{element.text}</a>
              </div>
            ))}
          </div>
        )}
      </div>
    ) : (
      <div className="menu-section">
        <p>{item.title}</p>
        <div className="menu-item double-item">
          <div class="single-item ">
            {item.items.map((element) => (
              <a key={element.text} href={element.link}>
                {element.text}
              </a>
            ))}
          </div>
          <div class="single-item remaining-items "></div>
        </div>
        <div className="menu-item"></div>
      </div>
    )
  );
};
