import Collapse from './Collapse';
import Data from './Data';
import React from 'react';

export default ({ product1 = {}, product2 = {}, product3 = {} }) => {
  return (
    <>
      <Collapse header="Quick Look" defaultOpen={true}>
        <Data
          quickLook
          data={{
            col1: product1['Quick Look'],
            col2: product2['Quick Look'],
            col3: product3['Quick Look'],
          }}
        />
      </Collapse>
      <Collapse header="Interfaces">
        <Data
          data={{
            col1: product1['Interfaces'],
            col2: product2['Interfaces'],
            col3: product3['Interfaces'],
          }}
        />
      </Collapse>
      <Collapse header="Performance">
        <Data
          data={{
            col1: product1['Performance'],
            col2: product2['Performance'],
            col3: product3['Performance'],
          }}
        />
      </Collapse>
      <Collapse header="Features">
        <Data
          data={{
            col1: product1['Features'],
            col2: product2['Features'],
            col3: product3['Features'],
          }}
        />
      </Collapse>
      <Collapse header="Core Functionality">
        <Data
          data={{
            col1: product1['Core Functionality'],
            col2: product2['Core Functionality'],
            col3: product3['Core Functionality'],
          }}
        />
      </Collapse>
      <Collapse header="IPsec/L2TP/PPTP VPN Functionality">
        <Data
          data={{
            col1: product1['IPsec/L2TP/PPTP VPN Functionality'],
            col2: product2['IPsec/L2TP/PPTP VPN Functionality'],
            col3: product3['IPsec/L2TP/PPTP VPN Functionality'],
          }}
        />
      </Collapse>
      <Collapse header="Advanced QoS Functionality">
        <Data
          data={{
            col1: product1['Advanced QoS Functionality'],
            col2: product2['Advanced QoS Functionality'],
            col3: product3['Advanced QoS Functionality'],
          }}
        />
      </Collapse>
      <Collapse header="Hardware">
        <Data
          data={{
            col1: product1['Hardware'],
            col2: product2['Hardware'],
            col3: product3['Hardware'],
          }}
        />
      </Collapse>
      <Collapse header="Certifications">
        <Data
          data={{
            col1: product1['Certifications'],
            col2: product2['Certifications'],
            col3: product3['Certifications'],
          }}
        />
      </Collapse>
      <Collapse header="Remarks" className="leading-snug whitespace-pre-wrap">
        <div className="mb-8 grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-16">
          <div>
            {product1['Remarks'] &&
              product1['Remarks'].map((item) => {
                return item.value;
              })}
          </div>
          <div>
            {product2['Remarks'] &&
              product2['Remarks'].map((item) => {
                return item.value;
              })}
          </div>

          <div className="hidden lg:block xl:block">
            {product3['Remarks'] &&
              product3['Remarks'].map((item) => {
                return item.value;
              })}
          </div>
        </div>
        {/* <Data data={product1['Remarks']} />
        <Data data={product2['Remarks']} />
        <div className="hidden lg:block xl:block">
          <Data data={product3['Remarks']} />
        </div> */}
      </Collapse>

      <p className="mt-20 footnote">
        [1] For a list of supported modems, please refer to{' '}
        <a href="https://www.peplink.com/technology/4g3g-modem-support/" target="_blank" rel="noopener noreferrer">
          https://www.peplink.com/technology/4g3g-modem-support/
        </a>
        .
        <br />
        [2] Recommendation for sizing purposes only. No software restrictions applied. <br />
        [3] Router Throughput and PepVPN/SpeedFusion Throughput is based on Ethernet frame sizes of 1280 or 1518 bytes.
        Actual performance can vary across different networking environments. Figures apply to the latest hardware
        revision. The throughput result is tested with DHCP/Static IP. <br />
        [4] The number of peer Peplink devices you can connect using SpeedFusion VPN. <br />
        [5] IPsec only supports connection with Cisco, Juniper, Peplink devices. <br />
        [6] Balance 20/30 LTE/One/210/310 comes with AP Controller Standard, which supports up to two configuration
        profiles with multiple SSIDs, meaning that all APs will share the same configuration, and you can only manage
        APs on the local physical LAN. 305/310 5G/310 5G Fiber/380/380X/580/580X/710/1350/2500/SDX/SDX Pro/EPX comes
        with AP Controller Pro, which supports multiple configuration profiles with multiple SSIDs, and you can manage
        remote APs outside the local physical LAN. <br />
      </p>
    </>
  );
};
