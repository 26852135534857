import {
  AP_COMPARISON_PAGE,
  AP_COMPARISON_PAGE_URL,
  AP_SD_SWITCH_PAGE,
  AP_SD_SWITCH_PAGE_URL,
  DEFAULT_PRODUCTS,
  SERIES,
} from 'definitions';
// import { jsPDF } from 'jspdf';
import { upperCase } from 'lodash';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import Balance from './Balance';
// import html2canvas from 'html2canvas';
import Item from './Item';
import Max from './Max';
import qs from 'query-string';
import React from 'react';
import services from 'services';

export default withRouter(({ history }) => {
  const [product1, setProduct1] = React.useState({ attributes: [] });
  const [product2, setProduct2] = React.useState({ attributes: [] });
  const [product3, setProduct3] = React.useState({ attributes: [] });
  const [options, setOptions] = React.useState([]);
  const { series = 'balance', model } = qs.parse(history.location.search);

  const [isLoading] = React.useState(false);

  const getOptions = React.useCallback(
    (type, value) => {
      axios.get(process.env.REACT_APP_SPREADSHEET_DATA).then((res) => {
        switch (type) {
          case 'series':
            setOptions(res.data[series]);

            break;
          default:
            Object.keys(res.data).forEach((i) => {
              if (res.data[i].some((item) => item.value === value)) {
                const { product1 } = qs.parse(history.location.search);
                setOptions(res.data[i]);
                if (!product1) {
                  services.productServices.getProduct(value).then((res) => setProduct1(res));
                }
              }
            });
            break;
        }
      });
    },
    [history.location.search, series]
  );

  const addQuery = React.useCallback(
    (key, value) => {
      const pathname = history.location.pathname;
      const searchParams = new URLSearchParams(history.location.search);
      searchParams.set(key, value);
      history.push({
        pathname: pathname,
        search: searchParams.toString(),
      });
    },
    [history]
  );

  React.useEffect(() => {
    const { series } = qs.parse(history.location.search);
    if (!series) {
      addQuery('series', 'max');
    }
  }, [addQuery, history.location.search]);

  React.useEffect(() => {
    const { series, product1, product2, product3 } = qs.parse(history.location.search);

    if (!product1 && !product2 && !product3) {
      history.push(
        `/?series=${series}&product1=${DEFAULT_PRODUCTS[series].product1}&product2=${DEFAULT_PRODUCTS[series].product2}&product3=${DEFAULT_PRODUCTS[series].product3}`
      );
    }
  }, [history]);

  React.useEffect(() => {
    if (series) {
      getOptions('series', series);
    }
  }, [series, getOptions]);

  React.useEffect(() => {
    if (model) {
      getOptions('model', model);
    }
  }, [model, getOptions]);

  const groupAttributes = ({ attributes, ...rest }) => {
    return {
      ...rest,
      ...attributes.reduce((acc, value) => {
        const dataByAttributeGroup = [
          ...(acc[value.attributeGroup] || []),
          {
            label: value.attributeLabel,
            value: value.attributeValue,
            sortOrder: value.sortOrder,
          },
        ].sort((a, b) => a.sortOrder - b.sortOrder);

        return { ...acc, [value.attributeGroup]: dataByAttributeGroup };
      }, {}),
    };
  };

  React.useEffect(() => {
    const query = qs.parse(history.location.search);
    if (query.product1 !== product1.productCode) {
      services.productServices.getProduct(query.product1).then(([res]) => setProduct1(groupAttributes(res)));
    }
    if (query.product2 !== product2.productCode) {
      services.productServices.getProduct(query.product2).then(([res]) => setProduct2(groupAttributes(res)));
    }
    if (query.product3 !== product3.productCode) {
      services.productServices.getProduct(query.product3).then(([res]) => setProduct3(groupAttributes(res)));
    }
  }, [product1.productCode, product2.productCode, product3.productCode, history.location.search]);

  const handleChange = (product, name) => {
    addQuery(name, product);
  };

  // const savePDF = async () => {
  //   setLoading(true);

  //   const canvas = await html2canvas(document.querySelector('#model-comparison'), {
  //     type: 'view',
  //     useCORS: true,
  //     imageTimeout: 0,
  //     allowTaint: true,
  //     scale: 1,
  //     logging: true,
  //     scrollX: 0,
  //     scrollY: -window.scrollY,
  //     background: 'null',
  //     onclone: function (clonedDoc) {
  //       clonedDoc.querySelectorAll('.pdf-hide').forEach((i) => i.classList.toggle('hidden'));
  //       clonedDoc.querySelectorAll('.pdf-show').forEach((i) => i.classList.toggle('hidden'));
  //     },
  //     dpi: 72,
  //   });

  //   var imgData = canvas.toDataURL('image/jpg');

  //   var imgWidth = 210;
  //   var pageHeight = 295;
  //   var imgHeight = (canvas.height * imgWidth) / canvas.width;
  //   var heightLeft = imgHeight;

  //   var doc = new jsPDF('p', 'mm');
  //   var position = 0;

  //   doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);

  //   heightLeft -= pageHeight;

  //   while (heightLeft >= 0) {
  //     position = heightLeft - imgHeight; // top padding for other pages
  //     doc.addPage();
  //     doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
  //     heightLeft -= pageHeight;
  //   }

  //   doc.save('model-comparison.pdf');
  //   setLoading(false);
  // };

  const handleSeriesSelect = (e) => {
    const series = e.target.value;

    if (series === AP_COMPARISON_PAGE) {
      window.open(AP_COMPARISON_PAGE_URL);
    } else if (series === AP_SD_SWITCH_PAGE) {
      window.open(AP_SD_SWITCH_PAGE_URL);
    } else {
      history.push(
        `/?series=${series}&product1=${DEFAULT_PRODUCTS[series].product1}&product2=${DEFAULT_PRODUCTS[series].product2}&product3=${DEFAULT_PRODUCTS[series].product3}`
      );
    }
  };

  return (
    <>
      <div id="model-comparison">
        <div className="mb-16 cover">
          <div className="container mx-auto ">
            <div className="relative px-10 py-16 mt-32 sm:px-0">
              <select defaultValue="" className=" select series-selector" onChange={handleSeriesSelect}>
                <option value="" disabled hidden>
                  Compare Other Product Series
                </option>
                <option value="balance">Balance</option>
                <option value="max">MAX</option>
                <option value={AP_SD_SWITCH_PAGE}>SD Switch</option>
                <option value={AP_COMPARISON_PAGE}>AP One</option>
              </select>
              <h1 className=" text-white">{SERIES[upperCase(series)]?.title}</h1>
              <h3 className="text-primary">{SERIES[upperCase(series)]?.description}</h3>
            </div>
          </div>
        </div>
        <main className="container px-10 mx-auto">
          <div className="mb-16 grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-16">
            <Item isLoading={isLoading} options={options} name="product1" data={product1} onChange={handleChange} />
            <Item isLoading={isLoading} options={options} name="product2" data={product2} onChange={handleChange} />
            <div className="hidden lg:block xl:block">
              <Item isLoading={isLoading} options={options} name="product3" data={product3} onChange={handleChange} />
            </div>
          </div>

          {series === SERIES.BALANCE.key && <Balance product1={product1} product2={product2} product3={product3} />}
          {series === SERIES.MAX.key && <Max product1={product1} product2={product2} product3={product3} />}

          {/* <div id="download-pdf" className="flex justify-center py-8 mb-20" data-html2canvas-ignore="true">
            <button className="inline-flex items-center btn btn-download" type="submit" onClick={savePDF}>
              {isLoading ? (
                <>
                  <svg
                    className="w-5 h-5 mr-3 -ml-1 text-white animate-spin"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>{' '}
                  LOADING
                </>
              ) : (
                'DOWNLOAD PDF'
              )}
            </button>
          </div> */}
        </main>
      </div>
    </>
  );
});
