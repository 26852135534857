import React from 'react';

export default ({ data }) => {
  return (
    <>
      {data?.length > 0 &&
        data.map((item, index) => (
          <div className="menu-section" key={index}>
            <p>{item.title}</p>
            <div className="menu-item">
              <div class="single-item ">
                {item.items.map((item, index) => (
                  <a key={index} href={item.link}>
                    {item.text}
                  </a>
                ))}
              </div>
              <div class="single-item remaining-items"></div>
            </div>
          </div>
        ))}
    </>
  );
};
